define([
    'lodash',
    'componentsCore',
    'compUtils',
    'wixappsCore',
    'santaPreviewComponents',

    //side effects
    'components'
], function (_,
             componentsCore,
             compUtils,
             wixapps,
             santaPreviewComponents) {
    'use strict';

    const proxyFactory = wixapps.proxyFactory;
    const logicFactory = wixapps.logicFactory;
    let extensions = {}; //eslint-disable-line santa/no-module-state
    let proxyExtensions = {}; //eslint-disable-line santa/no-module-state
    let logicExtensions = {}; //eslint-disable-line santa/no-module-state

    return {
        extendCompClasses: function extendCompClasses() {
            _.forEach(extensions, function (extensionsForComponent, compType) {
                _.forEach(extensionsForComponent, function (extension) {
                    compUtils.compFactory.extend(compType, extension);
                });
            });
        },
        registerPreviewClasses: () => {
            _.forEach(santaPreviewComponents.components, (compClass, compType) => componentsCore.compRegistrar.registerExtension(compType, compClass));
        },
        registerCompExtension: function registerCompExtension(compType, extension) {
            extensions[compType] = (extensions[compType] || []).concat([extension]);
        },
        registerProxyExtension(proxyType, extension) {
            proxyExtensions[proxyType] = extension;
        },
        extendProxyClasses() {
            _.forEach(proxyExtensions, function (extension, proxyType) {
                proxyFactory.extend(proxyType, extension);
            });
        },
        registerLogicExtension(partId, extension) {
            logicExtensions[partId] = extension;
        },
        extendLogicClasses() {
            _.forEach(logicExtensions, function (extension, partId) {
                logicFactory.extend(partId, extension);
            });
        },

        resetAllExtensions() {
            extensions = {};
            proxyExtensions = {};
            logicExtensions = {};
        }
    };
});
